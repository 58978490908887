import request from "@/api/request";
export function getCode(data) {
    return request({
        method: "post",
        url: "/api/enterprise/account/sms",
        data: data,
    });
}
export function getProtocol(number) {
    return request({
        method: "get",
        url: `/api/admin/protocol/details?number=${number}`,
    });
}
export function getDetails(id) {
    return request({
        method: "get",
        url: `/api/enterprise/trial/trialInfo?code=${id}`,
    });
}
export function trialSave(data) {
    return request({
        method: "post",
        url: "/api/enterprise/trial/save",
        data,
    });
}
//服务商h5 
export function generateschemeService(data) {
    return request({
        method: "post",
        url: "/api/currency/wechat/serve/generatescheme",
		 data,
    });
}
// 短信接口
export function getGeneratescheme(path) {
    return request({
        method: "get",
        url: `/api/currency/wechat/generatescheme?path=${path}`,
    });
}

// 微信公众号获取分享签名信息
export function getShareSign(path) {
    return request({
        method: "get",
        url: `/api/currency/wechat/getShareSign?url=${path}`,
    });
}