<template>
  <div class="flex-col page">
    <div class="flex-col header">
      <div class="justify-between group_2">
        <van-nav-bar :placeholder="true" :left-arrow="false" :fixed="true" title="申请免费使用" style="width: 100%" />
      </div>
    </div>
    <div class="swiper">
      <div class="flex-col items-center image-wrapper">
        <van-swipe class="my-swipe" :autoplay="6000" lazy-render indicator-color="white">
          <van-swipe-item @click="onClickSwiper(item, index)" v-for="(item, index) in images" :key="index">
            <img class="img" :src="item" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="flex-col group_4">
      <div class="flex-col">
        <div class="flex-col section_1">
          <div class="flex-row group_6">
            <van-field required :clearable="true" label-class="labelClass" v-model="form.enterpriseName" label="企业名称"
              placeholder="请输入" maxlength="50" @update:model-value="input1" />
          </div>
          <div class="flex-row group_6">
            <van-field required :clearable="true" label-class="labelClass" v-model="form.contact" label="姓名" placeholder="请输入"
              maxlength="50" @update:model-value="input2" />
          </div>
          <div class="flex-row group_6">
            <van-field required :clearable="true" label-class="labelClass" v-model="form.phone" label="电话" type="number"
              placeholder="请输入" maxlength="11" @update:model-value="input3" />
          </div>
          <div class="flex-row group_6">
            <van-field required :clearable="true" label-class="labelClass" v-model="form.code" label="验证码" type="number"
              placeholder="请输入">
              <template #button>
                <span v-if="time" class="text_12">
                  <van-count-down @finish="finishCount" format="ss" :time="time" />
                </span>
                <template v-else>
                  <span v-if="form.phone.length == 11" @click="getCode" class="text_12">获取验证码</span>
                  <span v-else style="color: #969799" class="text_12">获取验证码</span>
                </template>
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="flex-col group_10" v-show="hideshow">
        <div class="group_11">
          <span class="text_13">提交即视为同意</span>
          <span @click="toRichText(1001)" class="text_14">《企业服务协议》</span>
          <span class="text_15">、</span>
          <span @click="toRichText(1002)" class="text_16">《隐私政策》</span>
        </div>
        <div class="flex-col button">
          <div class="flex-col items-center text-wrapper">
            <van-button :disabled="
              form.enterpriseName && form.phone && form.contact && form.code
                ? false
                : true
            " @click="onClickApplication" size="large" color="#4685FF">申请试用</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trialSave, getCode } from "@/api/api_library/trial";
import { pc_url_pub } from "@/api/static_api";

export default {
  name: "trial",
  data() {
    return {
      // h5 底部被顶起
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true, //显示或者隐藏footer
      form: {
        enterpriseName: sessionStorage.getItem("enterpriseName")
          ? sessionStorage.getItem("enterpriseName")
          : "",
        phone: sessionStorage.getItem("phone")
          ? sessionStorage.getItem("phone")
          : "",
        contact: sessionStorage.getItem("contact")
          ? sessionStorage.getItem("contact")
          : "",
        source: "2", //来源（1官网;2公众号）
        code: "",
      },
      time: "",
      images: ["https://item.qn.ddingddang.com/dd16641687465717425.9421270652.jpeg"],
    };
  },
  mounted() {
	  let title = '叮当智采'
	  let infoDesc='申请试用'
	  let infoLink = 'https://h5.ddingddang.com/trial'
	  let infoImg='https://item.qn.ddingddang.com/dd16680447694367750.685233611629.png'
	  this.wxH5Share.getConfig(title, infoDesc, infoLink, infoImg);
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
  },
  // 使用window.onresize的方式对屏幕进行监听时，则这种监听是全局的监听，如果不注销则对其他页面屏幕的变化也会进行监听，所以必须在beforeDestroy中进行注销
  beforeUnmount() {
    window.onresize = () => { };
  },

  //监听
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
  },
  methods: {
	  
    input1(e) {
      sessionStorage.setItem("enterpriseName", e);
    },
    input2(e) {
      sessionStorage.setItem("contact", e);
    },
    input3(e) {
      sessionStorage.setItem("phone", e);
    },

    onClickApplication() {
      trialSave({
        ...this.form,
      })
        .then((res) => {
          this.$router.push({
            path: "/trialStatus",
            name: "trialStatus",
            params: {
              id: res.result.code,
            },
          });
          sessionStorage.setItem("trialId", res.result.code);
        })
        .catch((err) => { });
    },
    onClickSwiper(e, i) {
      window.open(pc_url_pub);
    },
    finishCount() {
      this.time = null;
    },
    getCode() {
      if (!this.isMobile(this.form.phone)) {
        this.$toast("手机号格式错误！");
        return;
      }
      this.time = 60 * 1000;
      getCode({
        phone: this.form.phone,
        type: 7, // 申请试用
      })
        .then((res) => {
          this.$toast("发送成功");
        })
        .catch((err) => { });
    },
    isMobile(e) {
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(e);
    },
    toRichText(e) {
      this.$router.push({
        path: "/richText",
        name: "richText",
        params: {
          id: e,
        },
      });
    },
  },
};

</script>
<style>
:root {
  --van-field-placeholder-text-color: #969799;
  --van-count-down-text-color: rgb(70, 133, 255);
  --van-count-down-font-size: 14px;
  --van-button-large-height: 44px;
  --van-button-round-border-radius: 5px;
  --van-button-border-radius: 5px;
}
</style>
<style scoped lang="scss">
@import "../../assets/css/common.scss";

.van-field {
  padding-left: 0;
}

/deep/.van-field__button {
  line-height: 20px;
}

/deep/.labelClass {
  color: #323233;
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
}

/deep/.van-field__control {
  font-size: 15px;
  line-height: 21px;
  white-space: nowrap;
}

.text_12 {
  color: rgb(70, 133, 255);
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.page {
  background-color: rgb(245, 245, 245);
  width: 100%;
  overflow-y: auto;
  min-height: 100vh;

  .header {
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    width: 100%;

    .group_2 {
      width: 100%;
      color: rgb(50, 50, 51);
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      white-space: nowrap;

      .image_3 {
        align-self: center;
        width: 9px;
        height: 16px;
      }

      .text_1 {
        margin: 4px 0 2px;
        text-transform: uppercase;
      }

      .image_4 {
        margin-left: 45px;
        border-radius: 14px;
        width: 88px;
        height: 28px;
      }
    }
  }

  .group_4 {
    flex: 1 1 auto;
    overflow-y: auto;

    .group_10 {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;

      .group_11 {
        line-height: 18px;
        white-space: nowrap;

        .text_13 {
          color: rgb(100, 101, 102);
          font-size: 13px;
          line-height: 18px;
        }

        .text_14 {
          color: rgb(70, 133, 255);
          font-size: 13px;
          line-height: 18px;
        }

        .text_15 {
          color: rgb(100, 101, 102);
          font-size: 13px;
          line-height: 18px;
        }

        .text_16 {
          color: rgb(70, 133, 255);
          font-size: 13px;
          line-height: 18px;
        }
      }

      .button {
        margin-top: 7px;
        padding: 8px 0;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;
        background-color: rgb(255, 255, 255);

        .text-wrapper {
          margin: 0 10px;
          // padding: 12px 0 10px;
          background-color: rgb(70, 133, 255);
          border-radius: 5px;
        }
      }
    }

    .section_1 {
      margin-top: 10px;
      padding-left: 16px;
      background-color: rgb(255, 255, 255);

      .group_6 {
        height: 50px;
        padding: 4px 0;
        border-bottom: solid 1px rgb(240, 240, 240);

        .text_2 {
          color: rgb(50, 50, 51);
          font-size: 15px;
          line-height: 21px;
          white-space: nowrap;
        }

        .text_3 {
          margin-left: 44px;
          color: rgb(150, 151, 153);
          font-size: 15px;
          line-height: 21px;
          white-space: nowrap;
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 190px;
    overflow: hidden;

    .image-wrapper {
      .my-swipe {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
