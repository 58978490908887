<template>
  <div class="page">
    <div class="flex-col header">
      <div class="justify-between group_2">
        <van-nav-bar
          :placeholder="true"
          :left-arrow="true"
          :fixed="true"
          :title="title"
          style="width: 100%"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="content">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { getProtocol } from "@/api/api_library/trial";
export default {
  name: "richText",
  data() {
    return {
      id: null,
      content: "",
      title: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getDetail(this.id);
  },
  methods: {
    getDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });

      getProtocol(this.id)
        .then((res) => {
          this.$toast.clear();
          this.content = res.result.content;
          this.title = res.result.name;
        })
        .catch(() => { });
    },
    // 点击左侧
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style>
:root {
  --van-nav-bar-icon-color: black;
}
</style>
<style scoped lang="scss">
.page {
  .content {
    text-align: left;
  }
}
</style>
