<template>
	<div class="businessCard">
		<div class="red"></div>
		这是一个名片
		<!-- <button @click="jump">回到小程序我的页面</button> -->
	</div>
</template>
<script>
	export default {
		name: "Home",
		data() {
			return {
			}
		},
		created() {},
		mounted() {
		},
		methods: {
		}
	}
</script>
<style  lang="scss">
	.businessCard{
		.red{
			width: 100px;
			height: 100px;
			background: red;
		}
	}
</style>